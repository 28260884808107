<template>
  <v-card fullscreen>
    <v-toolbar
      dark
      color="primary"
      dense
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Adicionar parceiro</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            v-model="partner.name"
            class="ma-4"
            cols="6"
            label="Nome da farmácia parceira *"
            :rules="$rules.required"
            required
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="partner.address"
            class="ma-4"
            label="Endereço *"
            :rules="$rules.required"
            required
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="partner.phone"
            v-mask="'(##)####-####'"
            class="ma-4"
            label="Telefone da Farmácia *"
            :rules="$rules.required"
            required
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="partner.discount"
            v-mask="'###'"
            class="ma-4"
            label="% de Desconto *"
            :rules="$rules.required"
            required
            outlined
          />
        </v-col>
        <v-text-field
          v-model="partner.installment"
          class="ma-4"
          label="N° de Parcelamento *"
          :rules="$rules.required"
          required
          outlined
        />
        <v-text-field
          v-model="partner.socialMedia"
          class="ma-4"
          label="Rede social *"
          :rules="$rules.required"
          required
          outlined
        />
        <v-col cols="12">
          <v-file-input
            v-model="imageFile"
            class="ma-4"
            label="Clique para adicionar imagens do tamanho mínimo de 1024x768."
            show-size
            truncate-length="31"
            :rules="imageAlreadyExists ? [] : $rules.required"
            outlined
            required
            :images="imagesPreview"
            accept="image/jpeg, image/png"
            @change="onFilesSelected"
          />
        </v-col>
        <v-col cols="12">
          <gallery
            :images="imagesPreview"
            :rules="$rules.required"
            @image-deleted="onDeleteImage"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-col
      class="d-flex justify-center"
      cols="12"
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="loading === true"
          color="primary"
          loading
          @click="submit"
        />
        <v-btn
          v-else
          color="primary"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask';
import DrugstorePartnerService from '../../../services/drugstore-partner.service';
import fileUpload from '../../../services/fileUpload';

export default {
  name: 'DrugstorePartnerForm',
  directives: { mask },
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      partner: {
        name: '',
        address: '',
        phone: '',
        socialMedia: '',
        discount: '',
        installment: '',
        imageUrl: '',
      },
      loading: false,
      imageFile: [],
      imagesPreview: [],
      imagesUpload: null,
    };
  },
  computed: {
    imageAlreadyExists() {
      return Boolean(this.partner.imageUrl) || Boolean(this.imagesUpload);
    },
  },
  async created() {
    if (this.populateWith.id) {
      this.partner = this.populateWith;
      this.imagesPreview = this.partner?.imageUrl
        ? [this.partner?.imageUrl]
        : [];
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    onFilesSelected(files) {
      this.imagesPreview = [URL.createObjectURL(files)];
    },

    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      if (this.imagesUpload) {
        this.imagesUpload.splice(index, 1);
      }

      this.partner = { ...this.partner, imageUrl: null };
    },

    async submit() {
      if (this.partner.imageUrl) {
        try {
          this.loading = true;
          await DrugstorePartnerService.update({
            ...this.partner,
          });
          this.$emit('partner-edited', this.partner);
          this.$toast.success('Parceiro editado com sucesso');
          this.loading = false;
          this.close();
        } catch (e) {
          this.loading = false;
          this.$handleHttpError(e);
        }
        return;
      }
      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }
      if (this.populateWith.id) {
        try {
          this.loading = true;
          const imageLink = this.imageBlob(this.imageFile);
          await this.getImageSize(imageLink);
          const imageSizes = await this.getImageSize(imageLink);
          if (imageSizes.imageWidth !== 1024 || imageSizes.imageHeight !== 768) {
            this.$toast.error('Por favor, coloque uma imagem de 1024x768');
            return;
          }
          const imageUpdate = await this.checkAndUploadImage();
          this.partner.imageUrl = imageUpdate;
          await DrugstorePartnerService.update({
            ...this.partner,
          });
          this.$emit('partner-edited', this.partner);
          this.$toast.success('Parceiro editado com sucesso');
          this.loading = false;
          this.close();
        } catch (e) {
          this.loading = false;
          this.$handleHttpError(e);
        }
      } else {
        try {
          this.loading = true;
          const imageLink = this.imageBlob(this.imageFile);
          await this.getImageSize(imageLink);
          const imageSizes = await this.getImageSize(imageLink);
          if (imageSizes.imageWidth !== 1024 || imageSizes.imageHeight !== 768) {
            this.$toast.error('Por favor, coloque uma imagem de 1024x768');
            return;
          }
          const imageCreate = await this.checkAndUploadImage();
          this.partner.imageUrl = imageCreate;
          await DrugstorePartnerService.create({
            ...this.partner,
          });
          this.$toast.success('Parceiro adicionado com sucesso');
          this.$emit('partner-added', {
            ...this.partner,
          });
          this.loading = false;
          this.close();
        } catch (e) {
          this.loading = false;
          this.$handleHttpError(e);
        }
      }
    },

    imageBlob(imageFile) {
      const imageUrl = URL.createObjectURL(imageFile);
      return imageUrl;
    },

    async checkAndUploadImage() {
      if (this.imageFile) {
        try {
          const formData = new FormData();
          formData.append('files', this.imageFile);
          const [response] = await fileUpload.upload(formData);
          return response;
        } catch (e) {
          this.$handleHttpError(e);
        }
      }
    },
    async getImageSize(imageLink) {
      try {
        const img = new Image();
        img.src = imageLink;
        await img.decode();
        return { imageWidth: img.width, imageHeight: img.height };
      } catch (e) {
        this.$toast.error('Erro ao processar imagem.');
      }
    },

    async findAllDrugstorePartners() {
      this.partner = await DrugstorePartnerService.findAll();
    },
  },
};
</script>
