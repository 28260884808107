import api from './api';

const url = 'upload/image';

const upload = (files) => api.post(url, files, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default { upload };
